import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class ReactMastering extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            react_mastering: file(relativePath: { eq: "courses/courses-logos/react-mastering.png" }) { ...thumb },

            mode1: file(relativePath: { eq: "courses/_general/mode/mode-thumb-1.png" }) { ...thumb },
            mode2: file(relativePath: { eq: "courses/_general/mode/mode-thumb-2.png" }) { ...thumb },
            mode3: file(relativePath: { eq: "courses/_general/mode/mode-thumb-3.png" }) { ...thumb },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#61dafb',
    separatorHeaderColor2: '#30b3d7',
    separatorColor1: '#61dafb',
    separatorColor2: '#30b3d7',
    buttonColor: '#30b3d7',
    panelBackground1: '#30b3d7',
    iconColor1: '#666',       // summary
    iconColor2: '#30b3d7',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso Avanzato su React: ',
    title: 'MASTERING REACT (ES6)',
    shortTitle: 'MASTERING <br /> REACT (ES6)',
    subtitle: 'Un corso avanzato sull\'ultima release di React per approfondire metodologie, pattern e best practice per l\'organizzazione di progetti scalabili e manutenibili',
    image: 'react_mastering',
    difficulty: 'Intermedio',
    duration: '8 ore (1g)'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Un corso dedicato agli sviluppatori che già utilizzano React ma desiderano approfondire argomenti come gli hooks (disponibili da React 16.8+), utilizzare tecniche come "Render Props" e "Higher Order Components", approfondire API di recente introduzione e il ciclo di vita dei componenti (React 16.6+) , creare progetti scalabili, testabili e codice efficiente.' },
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OBIETTIVO DEL CORSO', text: 'Approfondimento su React per sfruttarne il potenziale e ottenere una certa padronanza nell\'utilizzo delle ultime API fornite dalla libreria'},
    { title: 'REQUISITI', text: 'Conoscere le fondamenta del framework e/o aver partecipato al corso Angular Core Concepts e Mastering Angular.\n' +
        'E’ preferibile aver già creato in precedenza progetti Angular per trarre il massimo vantaggio dal corso e apprezzarne i contenuti' },
  ],
  metadata: [

  ],
  carousels: {
    main: null,
    reviews: null
  },
  videos: {
    teaser1: null
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '8 ore (1g)' },
    { icon: faChartBar, label: 'Livello:', value: 'Intermedio' },
    { icon: faCode, label: 'Versione Angular:', value: '8.x' },
    { icon: faCode, label: 'Versione Typescript:', value: '3.x' },
    { icon: faUser, label: 'Tipologia:', value: 'Corso in aula' },
  ],
  exampleLesson: {
    enabled: false,
    title: 'ESEMPIO LEZIONE',
    desc: '',
    video1: 'UXEvvLJhOBQ',
    bgColor: '#c3002f',
    items: [
      { text: ''},
    ]
  },
  mode: null,
  program: {
    colLeft: [


      { type: 'title', value: 'Best Practice' },
      { value: 'Organizzazione progetti scalabili e manutenibili', },
      { value: 'Component-based approach: approfondimento', },
      { value: 'Creare UI stateless', },
      { value: 'Differenti tecniche per la gestione dello stato applicativo', },
      { value: 'Preparare il progetto affinché sia pronto per l\'integrazione con Redux' },
      { value: 'Duck pattern', },
      { value: 'React Profiler', },

      { type: 'title', value: 'Pattern & Tecniche' },
      { value: 'Diversi esempi e casi d\'uso di "Higher Order Components"', },
      { value: 'Introduzione alla tecnica “render props”', },
      { value: 'Error Boundaries', },
      { value: 'Approfondimento React API (no JSX)', },
      { value: 'Creazione dinamica di componenti senza l\'utilizzo di JSX', },

      { type: 'title', value: 'Hooks' },
      { value: 'useState', },
      { value: 'useEffect', },
      { value: 'useContext', },
      { value: 'useReducer', },
      { value: 'useMemo', },
      { value: 'useRef', },
      { value: 'Ampio spazio dedicato alla creazione di "custom hooks"', },


    ],
    colRight: [


      { type: 'title', value: 'Component Lifecycle' },
      { value: 'Tutte le fasi del ciclo di vita del componente:', },
      { value: 'Immutabilità e ottimizzazione performance', },
      { value: 'LifeCycle in Functional Components attraverso gli hooks', },
      { value: 'React PureComponents', },
      { value: 'LifeCycle in Class Components:', },
      { value: '• componentDidMount', },
      { value: '• componentDidUpdate', },
      { value: '• componentWillUnmount', },
      { value: '• shouldComponentUpdate', },
      { value: '• getDerivedStateFromProps', },
      { value: '• getSnapshotBeforeUpdate', },
      { value: '• getDerivedStateFromError', },
      { value: '• getDerivedStateFromError', },

      { type: 'title', value: 'Altre API utili' },
      { value: 'Code Splitting e Lazy loading con "React.lazy" & "import"' },
      { value: 'Caricamento lazy di routes e componenti', },
      { value: 'Suspense e fallback', },
      { value: 'Context API', },
      { value: 'CreateRef (16.3 vs 16.6)', },



    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '8 ore (1g)'},
      { label: 'Accesso ad Area educational', extra: 'Slide & Esercizi'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: null,
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'
    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
}
